var $ = jQuery;

var totalItems = $('#testimonial_slider .carousel-item').length;
var currentIndex = $('div.active').index() + 1;

trigger_slide(0);

$('#testimonial_slider').on('slide.bs.carousel', function(ev) {
    trigger_slide(ev.to);
});

function trigger_slide(currentSlide) {
    currentIndex = currentSlide+1;
    var percentage = (currentIndex/totalItems)*100;
    $(".carousel-controls .stat").html(currentIndex+'/'+totalItems);
    $(".bar-outline .bar").width(percentage+"%");
}

// $("ul.sub-menu").focusout( function ( e ) {
//     if ( $.contains( this, e.relatedTarget ) ) { return; }
//     hide_submenu();
// });

// function hide_submenu() {
//     $('.header-menu li.menu-item').removeClass("active");
//     $("ul.sub-menu").removeClass("active");
// }

//$("ul.sub-menu:after").width("20px");

$('li.menu-item-has-children a').click(function(e) {
    if($(this).attr("href") == "#") {
        e.preventDefault();
    }
});

$(document).on('focus blur', '.ginput_container input', function(e) {
    var glabel = $(this).parents(".ginput_container").siblings("label.gfield_label");
    glabel.addClass("focus");
    if( e.type == 'focusout' ){ 
        if($(this).val().length === 0) {
            glabel.removeClass("focus");
        }
    }
});

$("button.read-more-content").click(function(){
    $(this).siblings(".hidden-content").slideDown("fast");
    $(this).hide();
    $(this).siblings(".read-less-content").slideDown("fast");
});

$("button.read-less-content").click(function(){
    $(this).siblings(".hidden-content").slideUp("fast");
    $(this).hide();
    $(this).siblings(".read-more-content").slideDown("fast");
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});