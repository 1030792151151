( function( $ ){

    let menu_trap        = focusTrap.createFocusTrap( '.header-menu' );

    $( '.mobile-menu-open' ).on( 'click', () => {
        $( '.header-menu' ).addClass( 'open' );
        $( '.site-logo' ).addClass( 'open' );
        menu_trap.activate();
        
    });

    $( '.mobile-menu-close' ).on( 'click', () => {
        $( '.header-menu' ).removeClass( 'open' );
        $( '.site-logo' ).removeClass( 'open' );
        menu_trap.deactivate();
    });
    
})( jQuery );
